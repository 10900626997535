import * as React from 'react';
import { useNavigate } from 'react-router-dom'

export const RootPage: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if( sessionStorage.getItem('drone_auth_token') != null &&
        sessionStorage.getItem('drone_auth_token') != null &&
        sessionStorage.getItem('drone_user_id') != null ) {
      navigate('/manage');
    } else {
      navigate('/login');
    }
  });
  
  return (
    <>
    </>
  );
}
