import * as React from 'react';
import { LoginComponent } from '../components/LoginComponent'
import { Container } from '@mui/material'

export const LoginPage: React.FC = () => {
  return (
    <Container maxWidth="md">
      <LoginComponent />
    </Container>
  );
}
