import * as React from 'react';
import { Stack, TextField, TextFieldProps } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom'

import { S3Client, S3, PutObjectCommand} from "@aws-sdk/client-s3";
import { fetch_ex_get, fetch_ex_put } from '../ts/Utility';

export const ManageComponent: React.FC = () => {

    const [loading , setLoading ] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState<File>();


    const upload = () => {

        fetch_ex_get('/s3').then(response => {
            if(!response.ok) {
                throw Error('Network response was not OK');
            }
            return response.json();
          }
        ).then(data => {
            const file: File = selectedFile!;
            return fetch_ex_put(data.body, file);
        }).then(response => {
            if(!response.ok) {
                throw Error('Network response was not OK');
            }
            alert('fin');
        });



        // const buketName = "drone-work-bucket";

        // const file: File = selectedFile!;
        // const fileName = file.name;
        // const photoKey = fileName;
        // const uploadParams = {
        //     Bucket: buketName,
        //     Key: photoKey,
        //     Body: file
        // };

        // var access_key_id = "AKIATCPKCU3QPGUHOREF";
        // var secret_access_key = "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";

        // var sessionid = "session";

        // const clinet = new S3Client({
        //     "region": "ap-northeast-1",
        //     "credentials" : {
        //         "accessKeyId" : access_key_id,
        //         "secretAccessKey" : secret_access_key
        //     }
        // });
        // clinet.send(new PutObjectCommand(uploadParams)).then((result) => {
        //     console.log(result);
        //     alert("Successfully uploaded photo.");
        // });
    }

    const changeHandler = (event:any) => {
		setSelectedFile(event.target.files[0]);
	};

    
    return (
        <>
            <input type="file" onChange={changeHandler}/>
			<div>
                <LoadingButton variant="contained" loading={loading} onClick={upload}>アップロード</LoadingButton>
			</div>
        </>
    );
}
