import * as React from 'react';

export const fetch_ex_get = (requestUrl: string) =>{
  const token = sessionStorage.getItem('drone_auth_token');
  const url = process.env.REACT_APP_APIURL + requestUrl;
  const request_method :string = 'GET';
  const requestOptions ={
      method: request_method,
      headers:{
        'Authorization': 'Bearer ' + token
      }
  };
  return fetch(url, requestOptions);
}

export const fetch_ex_put = (requestUrl: string, file: File) =>{
  const url = requestUrl;
  const request_method :string = 'PUT';
  const requestOptions ={
      method: request_method,
      data: file
  };

  return fetch(url, requestOptions);
}
