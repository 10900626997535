import * as React from 'react';
//import { LoginComponent } from '../components/LoginComponent'
import { Container } from '@mui/material'
import { ManageComponent } from '../components/ManageComponent';

import '../ts/Utility';
import { fetch_ex_get } from '../ts/Utility';

export const ManagePage: React.FC = () => {

  const [result , setResult ] = React.useState('');

  React.useEffect(()=>{
    fetch_ex_get('/manage/gateway').then(response => {
        if(!response.ok) {
            throw Error('Network response was not OK');
        }
        return response.json();
      }
    ).then(data => {
        setResult(data.body);
      }
    );
  }, []);

  return (
    <Container maxWidth="md">
      manage -&gt; { result }
      <ManageComponent />
    </Container>
  );
}
