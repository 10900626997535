import * as React from 'react';
import Button from 'react-bootstrap/Button';
import { Stack, TextField, TextFieldProps } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom'

export const LoginComponent: React.FC = () => {

    const [loading , setLoading ] = React.useState(false);    
    const login_id = React.useRef<TextFieldProps>(null);
    const password = React.useRef<TextFieldProps>(null);
    const navigate = useNavigate();

    function login() {
        setLoading(true);
        
        let isSuccess = false;
        const url = process.env.REACT_APP_APIURL + '/auth/login';
        const request_method :string = 'POST';
        const requestOptions ={
            method: request_method,
            headers:{'Content-Type': 'application/json'},
            body: JSON.stringify({
                username: login_id.current!.value,
                password: password.current!.value,
            })
        };
        fetch(url, requestOptions).then(response => {
                if(!response.ok) {
                    throw Error('Network response was not OK');
                }
                return response.json();
            }
        ).then(data => {
                console.log(data);
                sessionStorage.setItem('drone_auth_token', data.body.token);
                sessionStorage.setItem('drone_user_name', data.body.payload.name);
                sessionStorage.setItem('drone_user_id', data.body.payload.sub);
                sessionStorage.setItem('drone_authority', data.body.payload.authority);

                isSuccess = true;
            }
        ).finally(
            () => { 
                setLoading(false);
                if (isSuccess) {
                    navigate('/manage');
                }
            }
        );
        
    }

    return (
        <>
            <Stack direction="column" justifyContent="flex-start" spacing={2}>
                <TextField inputRef={login_id} label="企業ID" variant="standard" />
                <TextField inputRef={password} label="パスワード" type="password" variant="standard"/>
                <LoadingButton variant="contained" loading={loading} onClick={login}>ログインボタン</LoadingButton>
                <Button variant="outline-primary">プライマリーボタン</Button>
            </Stack>
        </>
    );
}
